import React from 'react'
import {Link, graphql,useStaticQuery} from 'gatsby'
import Layout from '../components/layout'
import blogStyles from './blog.module.scss'
import Head from '../components/head'


const BlogPage= () => {
    // const blogData=useStaticQuery(graphql`
    // query{
    //     allMarkdownRemark {
    //         edges {
    //           node {
    //             frontmatter {
    //               title
    //               date
    //             }
    //             fields{
    //                 slug
    //             }

    //           }
    //         }
    //       }
    //   }
    // `)

    const contentFullData=useStaticQuery(graphql`
    query{
        allContentfulBlogPost(
          sort:{
            fields:publishedDate,
            order:DESC
          }
        ){
          edges{
            node{
              title
              slug
              publishedDate(formatString:"MMMM Do, yyyy")
            }
          }
        }
      }
    `)
 
    return(
        <Layout>
          <Head title="Blogs"/>
            <h2> Blogs</h2>
            <ol className={blogStyles.posts}>
                {contentFullData.allContentfulBlogPost.edges.map((edge) => {
                    return(
                        <li className={blogStyles.post}>
                            <Link to={`/blog/${edge.node.slug}`}>
                            <h2>{edge.node.title}</h2>
                            <p>{edge.node.publishedDate}</p>
                            </Link>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}
 export default BlogPage